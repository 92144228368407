<template>
  <div>
    <!--    <b-alert variant="warning" v-model="showChainAlert">-->
    <!--&lt;!&ndash;      <span v-if="getChainName">Your currently selected chain is <strong>{{getChainName}}</strong> (a testnet).</span>&ndash;&gt;-->
    <!--      <span v-if="!getChainName">-->
    <!--        Please <a href="#" @click="connectWeb3Modal" class="alert-link">connect</a> with MetaMask or some other Ethereum wallet.-->
    <!--      </span>-->
    <!--    </b-alert>-->

    <div v-if="!isUserConnected">
      <b-alert show variant="warning">
        Please <a class="alert-link" href="#" @click="connectWeb3Modal">connect</a> with MetaMask or some other Ethereum
        wallet.
      </b-alert>
      <b-button variant="success" @click="connectWeb3Modal">
        Connect
      </b-button>
    </div>
    <div v-else>
      <!--      <Gravatar v-if="getActiveAccount" class="img-fluid avatar" :email="getActiveAccount" default-img="robohash" :size=200 />-->
      <p><strong>Your address:</strong> {{ getActiveAccount }}</p>
      <!--      <p><strong>Your balance:</strong> {{ Number(getActiveBalanceEth).toFixed(4) }} ETH</p>-->
      <b-button variant="danger" @click="disconnectWeb3Modal">
        Disconnect
      </b-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import Gravatar from "vue-gravatar";

export default {
  name: "Web3Component",

  components: {
    // Gravatar
  },

  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected", "getWeb3Modal", "getActiveAccount", "getActiveBalanceEth"]),

    showChainAlert() {
      switch (this.getChainName) {
        case "Mainnet":
          return false;
        default:
          return true;
      }
    }
  },

  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"])
  }
}
</script>

<style lang="scss" scoped>
.img-fluid.avatar {
  border: 1px solid gainsboro;
  border-radius: 50%;
}
</style>
