<template>
  <div v-if="isWeb2UserConnected" class="text-center">
    <!--    <Gravatar v-if="getWeb2ActiveAccount" class="img-fluid avatar" :email="getWeb2ActiveAccount.email" default-img="robohash" :size=200 />-->

    <p><strong>Your email:</strong> {{ getWeb2ActiveAccount.email }}</p>

    <b-button variant="danger" @click="disconnectAccount">
      Disconnect
    </b-button>
  </div>
  <div v-else>
    <b-tabs content-class="mt-3">
      <b-tab active title="Login">
        <div v-if="lostPassword">

        </div>
        <div v-else>
          <b-form v-if="show" @submit="onSubmitLogin">
            <b-form-group id="input-group-login-1">
              <b-form-input
                id="input-login-1"
                v-model="form.login.email"
                placeholder="Enter email"
                required
                type="email"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-login-2">
              <b-form-input
                id="input-login-2"
                v-model="form.login.password"
                placeholder="Enter password"
                required
                type="password"
              ></b-form-input>
            </b-form-group>
            <p>Lost your password?</p>
            <b-button type="submit" variant="primary">Submit</b-button>
          </b-form>
        </div>

      </b-tab>
      <b-tab title="Register">
        <b-form v-if="show" @submit="onSubmitRegister">
          <b-form-group id="input-group-register-0">
            <b-form-input
              id="input-register-0"
              v-model="form.register.name"
              placeholder="Enter name"
              required
              type="text"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-register-1" description="We'll never share your email with anyone else.">
            <b-form-input
              id="input-register-1"
              v-model="form.register.email"
              placeholder="Enter email"
              required
              type="email"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-register-11">
            <b-form-input
              id="input-register-11"
              v-model="form.register.email2"
              placeholder="Repeat email"
              required
              type="email"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-register-2">
            <b-form-input
              id="input-register-2"
              v-model="form.register.password"
              placeholder="Enter password"
              required
              type="password"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-register-22">
            <b-form-input
              id="input-register-22"
              v-model="form.register.password2"
              placeholder="Repeat password"
              required
              type="password"
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">Register</b-button>
        </b-form>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import Login from "../../api/Login";
import TokenStorage from "../../utils/TokenStorage";
import {mapActions, mapGetters} from "vuex";
// import Gravatar from "vue-gravatar";
import {VueReCaptcha} from 'vue-recaptcha-v3'

// For more options see below
Vue.use(VueReCaptcha, {siteKey: '6Lf_PGghAAAAAC_U5PX_G6jeg9gO3YxnU2mT6WR_'})

export default {
  name: "Web2Component",

  components: {
    // Gravatar
  },

  data() {
    return {
      lostPassword: false,
      isBusy: null,
      form: {
        login: {
          email: '',
          password: '',
        },
        register: {
          name: '',
          email: '',
          email2: '',
          password: '',
          password2: ''
        }
      },
      show: true
    }
  },

  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount", "isWeb2UserConnected"]),
  },

  methods: {
    ...mapActions("user", ["connectAccount", "disconnectAccount"]),

    async onSubmitLogin(event) {
      event.preventDefault()

      // TODO validate data

      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      Login.postLogin(token, {identifier: this.form.login.email, password: this.form.login.password})
        .then(response => {
          TokenStorage.set(response.data.jwt)
          this.connectAccount(response.data.user)
          this.$toasted.show('Login successful', {
            type: 'success',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        })
        .catch(error => {
          console.log(error, 'error')
          this.$toasted.show('The user or password is incorrect', {
            type: 'danger',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        });
    },

    async onSubmitRegister(event) {
      event.preventDefault()

      // TODO end this validation
      if (this.form.register.email !== this.form.register.email2 ||
        this.form.register.password !== this.form.register.password2
      ) {
        return
      }
      const token = await this.$recaptcha('login')
      Login.postRegister(token, {
        name: this.form.register.name,
        email: this.form.register.email,
        username: this.form.register.email,
        password: this.form.register.password
      })
        .then(response => {
          TokenStorage.set(response.data.jwt)
          this.connectAccount(response.data.user)
          this.$toasted.show('Register successful', {
            type: 'success',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        })
        .catch(error => {
          console.log({error})
          this.$toasted.show("Invalid email address", {
            type: 'error',
            duration: 5000,
            theme: "bubble",
            position: "top-center"
          });
        });
    },

    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.login.name = ''
      this.form.login.email = ''
      this.form.login.password = ''
      this.form.register.email = ''
      this.form.register.email2 = ''
      this.form.register.password = ''
      this.form.register.password2 = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img-fluid.avatar {
  border: 1px solid gainsboro;
  border-radius: 50%;
}
</style>
