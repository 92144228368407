<template>
  <b-container class="dashboard py-5">
    <h1>Sign in</h1>
    <p>You can sign in with a normal Web2 account in order to surf the site and buy trees. Afterwards you'll can
      connect your Web3 wallet in order to mint and collect what you bought on the blockchain.</p>

    <b-row>
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <b-card-title class="text-center">Web2</b-card-title>
          <b-card-body>
            <Web2Component/>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <b-card-title class="text-center">Web3</b-card-title>
          <b-card-body class="text-center">
            <Web3Component/>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import Web2Component from "../components/Dashboard/Web2Component";
import Web3Component from "../components/Dashboard/Web3Component";

export default {
  name: 'Dashboard',

  components: {
    Web2Component,
    Web3Component
  }
}
</script>
